// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <p>Hello World</p>
  );
}

export default App;
